<template>
  <lazy-account-authentication-basic v-if="$feature.tempHeaderVariation === 'basic'" v-bind="forwarded" />
  <lazy-account-authentication v-else v-bind="forwarded" />
</template>

<script lang="ts" setup>
import { useForwardPropsEmits } from 'radix-vue'
import type { Props } from '#commerce/components/account/Authentication.vue'

const props = defineProps<Props>()
const forwarded = useForwardPropsEmits(props)

const LazyAccountAuthentication = defineAsyncComponent(() =>
  import('#commerce/components/account/Authentication.vue')
)
</script>
